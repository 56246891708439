import { HackleUser, IdentifiersBuilder, IdentifierType, Properties, User } from "../../../core/internal/model/model"
import { IStorage } from "../../../core/internal/storage/Storage"
import { UserListener } from "../../../core/internal/user/UserListener"
import { isSameUser, mergeUsers, UserManager } from "../../../core/internal/user/UserManager"
import ObjectUtil from "../../../core/internal/util/ObjectUtil"
import { DEVICE_ID_STORAGE_KEY, USER_ID_STORAGE_KEY } from "../../../config"
import { PropertyOperations } from "../../property/PropertyOperations"
import { UserCohorts } from "../../../core/internal/user/UserCohort"
import { UserCohortFetcher } from "../../../core/internal/user/UserCohortFetcher"
import HacklePropertyGenerator from "../../property/HacklePropertyGenerator"
import Logger from "../../../core/internal/logger"
import { Clock } from "../../../core/internal/util/TimeUtil"

export class UserManagerImpl implements UserManager {
  private readonly userListeners: UserListener[] = []

  private readonly defaultUser: User = { id: this.hackleDeviceId, deviceId: this.hackleDeviceId }
  private context: UserContext

  constructor(
    private readonly hackleDeviceId: string,
    private readonly storage: UserStorage,
    private readonly cohortFetcher: UserCohortFetcher,
    private readonly clock: Clock,
    previousUser: User | null,
    initUser: User | null
  ) {
    this.context = this.initContext(previousUser, initUser)
    this.storage.saveUser(this.context.user)
  }

  private initContext(previousUser: User | null, initUser: User | null): UserContext {
    const user = initUser ?? previousUser ?? this.defaultUser
    return UserContext.of(decorate(user, this.hackleDeviceId), UserCohorts.empty())
  }

  public addListener(listener: UserListener): void {
    this.userListeners.push(listener)
  }

  public get currentUser() {
    return this.context.user
  }

  resolve(user?: User | string): HackleUser {
    const context = this.resolveContext(user)
    return this.hackleUser(context)
  }

  private resolveContext(user: User | string | undefined): UserContext {
    if (user === undefined) {
      return this.context
    }

    if (typeof user === "string") {
      return this.updateUser({ id: user })
    }

    return this.updateUser(user)
  }

  toHackleUser(user: User): HackleUser {
    const context = this.context.with(user)
    return this.hackleUser(context)
  }

  private hackleUser(context: UserContext): HackleUser {
    const builder = new IdentifiersBuilder()
      .addIdentifiers(context.user.identifiers || {})
      .add(IdentifierType.ID, context.user.id || this.hackleDeviceId)
      .add(IdentifierType.DEVICE, context.user.deviceId || this.hackleDeviceId)
      .add(IdentifierType.HACKLE_DEVICE, this.hackleDeviceId)

    if (ObjectUtil.isNotNullOrUndefined(context.user.userId)) {
      builder.add(IdentifierType.USER, context.user.userId)
    }
    const identifiers = builder.build()
    return {
      identifiers: identifiers,
      properties: context.user.properties || {},
      hackleProperties: this.hackleProperties(),
      cohorts: context.cohorts.rawCohorts
    }
  }

  private hackleProperties(): Properties {
    let hackleProperties
    if (typeof window !== "undefined") {
      hackleProperties = HacklePropertyGenerator.generate(window)
    }
    return hackleProperties || {}
  }

  public setUser(user: User): User {
    return this.updateUser(user).user
  }

  public setUserId(userId: string | undefined): User {
    const user: User = {
      ...this.context.user,
      userId
    }
    return this.setUser(user)
  }

  public setDeviceId(deviceId: string): User {
    const user: User = {
      ...this.context.user,
      deviceId
    }
    return this.setUser(user)
  }

  public updateUserProperties(operations: PropertyOperations): User {
    return this.operateProperties(operations).user
  }

  public resetUser(): User {
    return this.updateContext(() => this.defaultUser).user
  }

  private changeUser(oldUser: User, newUser: User, timestamp: number) {
    this.userListeners.forEach((listener) => {
      listener.onUserUpdated(oldUser, newUser, timestamp)
    })
  }

  private saveUser(user: User) {
    this.storage.saveUser(user)
  }

  private updateContext(updater: (user: User) => User): UserContext {
    const oldUser = this.context.user
    const newUser = updater(oldUser)

    const newContext = this.context.with(newUser)
    this.context = newContext

    if (!isSameUser(oldUser, newUser)) {
      this.changeUser(oldUser, newUser, this.clock.currentMillis())
      this.saveUser(newUser)
    }

    return newContext
  }

  private updateUser(user: User): UserContext {
    return this.updateContext((currentUser) => mergeUsers(currentUser, decorate(user, this.hackleDeviceId)))
  }

  private operateProperties(operations: PropertyOperations): UserContext {
    return this.updateContext((currentUser) => {
      const userProperties = currentUser.properties ?? {}
      const properties = operations.operate(new Map(Object.entries(userProperties)))

      return { ...currentUser, properties: ObjectUtil.fromMap(properties) }
    })
  }

  async sync(): Promise<void> {
    try {
      const userCohorts = await this.cohortFetcher.fetch(this.currentUser)
      this.context = this.context.update(userCohorts)
    } catch (e) {
      Logger.log.error(`Failed to sync cohorts: ${e}`)
    }
  }

  async close(): Promise<void> {}
}

export class UserStorage {
  constructor(private readonly storage: IStorage) {}

  public getUser(): User | null {
    const deviceId = this.deviceId || undefined
    const userId = this.userId || undefined

    if (deviceId !== undefined || userId !== undefined) {
      return { deviceId, userId }
    }

    return null
  }

  public saveUser(user: User) {
    this.setDeviceId(user.deviceId || null)
    this.setUserId(user.userId || null)
  }

  public get deviceId(): string | null {
    return this.storage.getItem(DEVICE_ID_STORAGE_KEY)
  }

  public get userId(): string | null {
    return this.storage.getItem(USER_ID_STORAGE_KEY)
  }

  public setDeviceId(deviceId: string | null) {
    this.setId(DEVICE_ID_STORAGE_KEY, deviceId)
  }

  public setUserId(userId: string | null) {
    this.setId(USER_ID_STORAGE_KEY, userId)
  }

  private setId(key: string, value: string | null) {
    if (ObjectUtil.isNotNullOrUndefined(value)) {
      this.storage.setItem(key, value)
    } else {
      this.storage.removeItem(key)
    }
  }
}

class UserContext {
  private constructor(readonly user: User, readonly cohorts: UserCohorts) {}

  static of(user: User, cohort: UserCohorts): UserContext {
    return new UserContext(user, cohort.filterBy(user))
  }

  with(user: User): UserContext {
    return UserContext.of(user, this.cohorts.filterBy(user))
  }

  update(cohorts: UserCohorts): UserContext {
    const filtered = cohorts.filterBy(this.user)
    const newCohorts = this.cohorts.toBuilder().putAll(filtered).build()
    return UserContext.of(this.user, newCohorts)
  }
}

const decorate = (user: User, hackleDeviceId: string): User => {
  return {
    ...user,
    id: user.id || hackleDeviceId,
    deviceId: user.deviceId || hackleDeviceId
  }
}
